import { Box, Flex, Grid, GridItem, Spacer, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Title } from "../../components/atoms/Title";
import { BannerCarousel } from "../../components/molecules/BannerCarousel";
import { HDiscountStack } from "../../components/molecules/HDiscountStack";
import { TopMenu } from "../../components/organisms/TopMenu";
import { useSettings, useTheme } from "../../context/index";
import { useGeolocation } from "../../context/geolocation";
import { useQuery } from "react-query";
import { axiosInstanceV2 } from "../../axios";
import { BookHStack } from "../Books/components/BookHStack";
import { getBookActivities, getFeaturedBooks, getMostReadBooks } from "../../api/books";
import { useProfile } from "../../context/user";
import { storage } from "../../utils/storage";

function CategoryTag({ name, imageUrl, categoryId, isBook = false }) {
    const { primaryColor, bookstoreType } = useTheme();
    return (
        <Link to={isBook ? (bookstoreType === "book" ? '/biblioteca' : '/banca') : "/busca"} state={categoryId}>
            <Flex
                backgroundImage={`linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0) 60%,
                rgba(0, 0, 0, 0.3) 90%,
                rgba(0, 0, 0, 0.5) 100%
            ), url(${imageUrl});`}
                backgroundSize="cover"
                backgroundColor={primaryColor}
                borderRadius="2px"
                width="100%"
                height={{ base: "30vw", md: "200px" }}
                className="layered-box-strong"
                paddingLeft="5%"
                paddingBottom="5%"
            >
                <Title
                    textShadow="0px 0px 5px black"
                    fontSize="10pt"
                    marginTop="auto"
                    color="white"
                >
                    {name}
                </Title>
            </Flex>
        </Link>
    );
}

export function HomePage() {
    const { id, name, primaryColor, titleColor, banners, bookstoreModule, ecommerceModule, localModule, bookstoreType } = useTheme();
    const { isApp } = useSettings();
    const {logged} = useProfile();
    const navigate = useNavigate();
    const geolocation = useGeolocation();
    const activities = useQuery("activities", () =>
        axiosInstanceV2.get('/activities/').then(({data}) => data)
    );
    const {
        data: mostReadBooks=[],
        isLoading: isMostBooksLoading
    } = useQuery(["most-read-books", id], () => getMostReadBooks(id), {
        enabled: !!bookstoreModule
    });
    const {
        data: featuredBooks=[],
        isLoading: isFeaturedBooksLoading
    } = useQuery(["featured-books", id], () => getFeaturedBooks(id),{
        enabled: !!bookstoreModule
    });
    const {
        data: bookActivities,
        isLoading: isBookCategoriesLoading
    } = useQuery(["book-activities", id], () => getBookActivities(id),{
        enabled: !!bookstoreModule
    });

    function onSelectBook(book) {
        navigate({ pathname: bookstoreType === "book" ? '/biblioteca' : '/banca' }, { state: { book } });
    }

    // useEffect(() => {
    //     if (!localStorage.getItem("forcePlan")) return;

    //     localStorage.removeItem("forcePlan");
    //     navigate({ pathname: "/combos" });
    // }, []);

    useEffect(() => {
        if(bookstoreModule && logged !== undefined && !logged) navigate('login')
    }, [bookstoreModule, logged]);

    return (
        <VStack
            alignItems="flex-start"
            paddingBottom="150px"
            width="100%"
            spacing={{ base: 5, md: 8 }}
            position="relative"
            zIndex="3"
        >
            <TopMenu />
            <VStack width="100%" align="flex-start">

                <Flex
                    width="90%"
                    margin="auto"
                    display={{ base: "none", md: "block" }}
                >
                    <BannerCarousel
                        padding="60px 0px 10% 0px"
                        banners={banners}
                    />
                </Flex>
                <Flex width="100%" display={{ base: "block", md: "none" }}>
                    <BannerCarousel isMobile banners={banners} />
                </Flex>
            </VStack>

            <Spacer />

            {ecommerceModule || localModule ?
                <> <HDiscountStack
                    title={`Preferidos do(a) ${name}`}
                    forceMode="contain"
                    queryName="featuredPromotions"
                    query={{ featured: true }}
                    seeMoreLink="/busca"
                />
                    {geolocation.hasGeolocation() ? (
                        <HDiscountStack
                            title="Perto de você"
                            forceMode="cover"
                            queryName="nearby"
                            queryEnabled={!!storage.getAgreementId()}
                            seeMoreLink="/busca"
                            query={{
                                mode: "local",
                                ...geolocation.getLocationObject()
                            }}
                        />
                    ) : (
                        <></>
                    )}
                    <HDiscountStack
                        title="Recentes"
                        forceMode="contain"
                        queryName="newPromotions"
                        // TODO: melhorar a  habilitação da query,quando nao existir o agreement (evita quebrar o back)
                        queryEnabled={!!storage.getAgreementId()}
                        query={{ new: true }}
                    />
                    <HDiscountStack
                        title={`Exclusivas ${name}`}
                        forceMode="cover"
                        queryName="exclusivePromotions"
                        queryEnabled={!!storage.getAgreementId()}
                        query={{ exclusive: true, agreement: id }}
                        seeMoreLink="/busca"
                    /> </> : <></>}

            <Box paddingTop="0px" />
            {bookstoreModule ?
                <VStack width="100%" spacing={10}>
                    <BookHStack 
                      padding="5%" title={`${bookstoreType === "book" ? "Livros" : "Revistas"} do Mês`}
                      subtitle={`${bookstoreType === "book" ? `Abaixo estão os livros do mês. Os livros abaixo que pertencem ao seu combo são seus para sempre! Todo mês, até o dia 05, você receberá novos livros. Todos esses títulos estarão disponíveis na aba "Livros Recebidos".`: `Abaixo estão as revistas do mês. As revistas abaixo que pertencem ao seu combo são suas para sempre! Todo mês, até o dia 31, você receberá novas revistas. Todos esses títulos estarão disponíveis na aba "Revistas Recebidas`}`}
                    isLoading={isFeaturedBooksLoading} onSelectBook={onSelectBook}
                        books={featuredBooks} seeMoreLink={bookstoreType === "book" ? '/biblioteca' : '/banca'} />
                    <BookHStack padding="5%" title={`Mais Lidos`} isLoading={isMostBooksLoading} onSelectBook={onSelectBook}
                        books={[...mostReadBooks, ...featuredBooks]} seeMoreLink={bookstoreType === "book" ? '/biblioteca' : '/banca'} />
                </VStack> : <></>}


            {localModule || ecommerceModule ? <>
                <Title color={titleColor} paddingLeft="5%" textAlign="left">
                    O que deseja fazer?
                </Title>
                <Grid
                    alignSelf="center"
                    width="90%"
                    templateColumns={{
                        base: "repeat(2, 1fr)",
                        md: "repeat(3, 1fr)"
                    }}
                    gap={5}
                >
                    {(activities?.data || []).map((a) => (
                        <GridItem>
                            <CategoryTag
                                key={a.name}
                                categoryId={a.categories}
                                name={a.name}
                                imageUrl={a.image}
                            />
                        </GridItem>
                    ))}
                </Grid>
            </> : <></>}
            {bookstoreModule ? <>
                <Title color={titleColor} paddingLeft="5%" textAlign="left">
                    O que deseja ler?
                </Title>
                <Grid
                    alignSelf="center"
                    width="90%"
                    templateColumns={{
                        base: "repeat(2, 1fr)",
                        md: "repeat(3, 1fr)"
                    }}
                    gap={5}
                >
                    {(bookActivities || []).map((a) => (
                        <GridItem>
                            <CategoryTag
                                categoryId={a.categories}
                                name={a.name}
                                imageUrl={a.image}
                                isBook={true}
                            />
                        </GridItem>
                    ))}
                </Grid>
            </> : <></>}
        </VStack>
    );
}
